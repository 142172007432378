import { Injectable } from '@angular/core';
import { environment } from 'ecommerce/main/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private environmentObject: any;

  constructor() {
    this.environmentObject = environment;
  }

  public getEnvironment(): any {
    return this.environmentObject;
  }
}
